<script setup lang="ts">
import { Notification } from '@shopware-pwa/composables-next'

defineEmits<{
  (e: 'click:close', id: number): void
}>()

const props = defineProps<{
  notification: Notification
}>()
</script>
<template>
  <div class="site-notification f-18" :class="props.notification.type">
    <Icon v-if="props.notification.type === 'danger'" name="alert" />
    <Icon v-if="props.notification.type === 'success'" name="check" />
    {{ notification.message }}
  </div>
</template>
